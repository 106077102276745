import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+next@1.5.0_@prismicio+client@7.3.1_next@14.1.1_react@18.2.0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+next@1.5.0_@prismicio+client@7.3.1_next@14.1.1_react@18.2.0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@prismicio+next@1.5.0_@prismicio+client@7.3.1_next@14.1.1_react@18.2.0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-axiom@1.1.1_next@14.1.1_react@18.2.0/node_modules/next-axiom/dist/webVitals/components.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/src/app/emergency.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/quick-menu-section.tsx");
import(/* webpackMode: "eager" */ "/app/src/assets/icons/sprite.svg");
import(/* webpackMode: "eager" */ "/app/src/components/layout/main-nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/layout/mobile-nav.tsx")